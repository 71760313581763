<template>
    <div>
        <div v-if="requestCalls > 0">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-body text-center">
                        <skeleton-loading></skeleton-loading>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6 header-body">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0"> {{ tt('kpi_workbench') }}</h3>
                            </div>
                            <div class="col-6">

                            </div>
                            <div class="col-text-right pr-3">
                                <base-button size="sm" @click="filterModal()">{{ tt('filter') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div id="tableKPI" class="table-responsive" style="height: 500px">
                        <div v-if="dataKPI.length > 0">
                            <table class="table table-flush active-hover">
                                <thead class="card-header bg-primary">
                                    <tr>
                                        <td class="align-middle text-white">{{ tt('name') }}</td>
                                        <td class="align-middle text-white">{{ tt('company_code') }}</td>
                                        <td class="align-middle text-white">{{ tt('role') }}</td>
                                        <th class="align-middle text-center text-white">{{ tt('result') }}</th>
                                    </tr>
                                </thead>
                                <tbody v-for="data in dataKPI" :key="Math.random() + data.name">
                                    <td class="text-capitalize">{{ data.name }}</td>
                                    <td class="text-capitalize">{{ data.company_code }}</td>
                                    <td class="text-capitalize">
                                        <span v-for="role in data.roles" :key="role.name + Math.random()">
                                            <label for="" class="badge badge-success mr-1">{{ role.name }}</label>
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span class="fw-bold badge badge-info" v-if="data.cat_count != '0'">{{ data.cat_count
                                            }}</span>
                                        <span class="text-danger" v-else> {{ data.cat_count }}</span>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center" style="height: 100px" v-else>
                            <br><br>
                            <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                            <p class="text-muted">{{ tt('empty') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <validation-observer>
                <modal :show.sync="showFilterModal">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('filter') }}</h5>
                        <i class="fa fa-times text-white text-right"></i>
                    </template>
                    <label class="form-control-label">{{ tt('company') }}</label>
                    <el-select class="form-control-sm col-md-12 mb-4" v-model.trim="filter.company" filterable remote
                        reserve-keyword :placeholder="tt('company')" :remote-method="queryCompany"
                        :loading="selectSearch.companyLoading" :automatic-dropdown=false clearable>
                        <el-option v-for="company in optionCompany" :key="company.company_code"
                            :value="company.company_code"
                            :label="company.company_code + ' - ' + company.company_description"></el-option>
                    </el-select>
                    <label class="form-control-label">{{ tt('name') }}</label>
                    <el-select class="form-control-sm col-md-12 mb-4" v-model.trim="filter.name" filterable remote
                        reserve-keyword :placeholder="tt('name')" :remote-method="queryName"
                        :loading="selectSearch.nameLoading" :automatic-dropdown="false" clearable>
                        <el-option v-for="name in optionName" :key="name.namecode" :value="name.namecode"
                            :label="name.name"></el-option>
                    </el-select>
                    <label class="form-control-label">{{ tt('role') }}</label>
                    <el-select class="form-control-sm col-md-12 mb-4" v-model.trim="filter.role" filterable remote
                        reserve-keyword :placeholder="tt('role')" :remote-method="queryRole"
                        :loading="selectSearch.roleLoading" :automatic-dropdown="false" clearable>
                        <el-option v-for="role in optionRole" :key="role.name" :value="role.name"
                            :label="role.name"></el-option>
                    </el-select>
                    <label class="form-control-label">{{ tt('range_date') }}</label>
                    <div class="row">
                        <div class="col-6">
                            <el-input class="form-control-sm col-md-12 mb-4" v-model="filter.from_date" type="date"
                                size="big"></el-input>
                        </div>
                        <div class="col-6">
                            <el-input class="form-control-sm col-md-12 mb-4" v-model="filter.to_date" type="date"
                                size="big" :disabled="filter.from_date == null || filter.from_date == ''"
                                @blur="checkDate()"></el-input>
                        </div>
                    </div>
                    <!-- <el-select class="form-control-sm col-md-12 mb-4" v-model.trim="filter.date"
                        :placeholder="tt('date')" clearable>
                        <el-option v-for="date in optionDate" :key="date" :value="date"
                            :label="'Per ' + date"></el-option>
                    </el-select> -->
                    <template slot="footer">
                        <base-button type="primary" @click="filterAction()" :disabled="btnShowFilter.onLoading">
                            <span v-if="btnShowFilter.onLoading"><i class="fas fa-spinner fa-spin"></i>{{
                                tt("please_wait") }}</span>
                            <span v-else>{{ tt('filter') }}</span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
    </div>
</template>

<script>
// import {mapState} from 'vuex'
import Api from '@/helpers/api';
import companyCode from '@/services/master/companyCode.service'
import byName from '@/services/manage/manageAccount.service';
import byRole from '@/services/manage/manageRole.service';
import WorkbenchMaterial from '@/services/workbench/WorkbenchMaterial.service';

export default {
    data() {
        return {
            showFilterModal: false,
            btnShowFilter: {
                onLoading: false,
            },
            selectSearch: {
                companyLoading: false,
                nameLoading: false,
                roleLoading: false,
                loadTimeout: null,
            },
            optionCompany: [],
            optionDate: [
                'Day', 'Week', 'Month', 'Year'
            ],
            optionName: [],
            optionRole: [],
            filter: {
                company: null,
                name: null,
                date: null,
                role: null,
                from_date: null,
                to_date: null,
            },
            dataKPI: [],
            dateFrom: '',
            dateTo: '',
            requestCalls: 0,
        }
    },
    computed: {
        validationCheck() {
            if (!this.filter.company && !this.filter.name && !this.filter.from_date && !this.filter.to_date && !this.filter.role) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        this.get();
    },
    methods: {
        get() {
            let context = this;
            this.requestCalls++;
            Api(context, WorkbenchMaterial.dataKPI()).onSuccess((response) => {
                context.dataKPI = response.data.data.data;
                context.dateFrom = response.data.data.dateFrom;
                context.dateTo = response.data.data.dateTo;
            }).onError((error) => {
                context.dataKPI = [];
            }).onFinish(() => {
                context.requestCalls--;
            }).call();
        },
        filterAction() {
            let context = this;
            this.btnShowFilter.onLoading = true;
            if (this.validationCheck) {
                this.btnShowFilter.onLoading = false;
                this.showFilterModal = false;
                this.get();
                return;
            }

            this.requestCalls++;
            Api(context, WorkbenchMaterial.dataKPI(this.filter)).onSuccess((response) => {
                context.dataKPI = response.data.data.data;
                context.dateFrom = response.data.data.dateFrom;
                context.dateTo = response.data.data.dateTo;
            }).onError((error) => {
                context.dataKPI = [];
            }).
                onFinish(() => {
                    context.btnShowFilter.onLoading = false;
                    context.showFilterModal = false
                    context.requestCalls--;
                }).call();

        },
        filterModal() {
            this.showFilterModal = true;
        },
        queryCompany(query) {
            if (query.length > 1) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;

                this.selectSearch.companyLoading = true;

                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, companyCode.get(null, { per_page: 'none', search: query }))
                        .onSuccess(function (response) {
                            context.optionCompany = response.data.data.data.data;
                            context.selectSearch.companyLoading = false;
                        }).onError(function (error) {
                            context.optionCompany = [];
                            context.selectSearch.companyLoading = false;
                        }).call()
                }, 200)
            }
        },
        checkDate() {
            if (this.filter.from_date > this.filter.to_date) {
                this.$notify({
                    message: this.tt("to_date_higher_than_from_date"),
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                this.filter.to_date = null
                return;
            }
        },
        queryName(query) {
            if (query.length > 1) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;
                this.selectSearch.nameLoading = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, byName.get(null, { per_page: 'none', search: query }))
                        .onSuccess((response) => {
                            context.optionName = response.data.data.data.data;
                            context.selectSearch.nameLoading = false;
                        })
                        .onError((error) => {
                            context.optionName = [];
                            context.selectSearch.nameLoading = false;
                        }).call();
                })
            }
        },
        queryRole(query) {
            if (query.length > 1) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;
                this.selectSearch.roleLoading = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, byRole.get(null, { per_page: 'none', search: query }))
                        .onSuccess((response) => {
                            context.optionRole = response.data.data.data.data;
                            context.selectSearch.roleLoading = false;
                        })
                        .onError((error) => {
                            context.optionRole = [];
                            context.selectSearch.roleLoading = false;
                        }).call()
                })
            }
        }
    }
}

</script>
<style>
.active-hover>tbody:hover {
    background-color: #ffe391;
}
</style>